import Logo from "./assets/Logo.png";
import Hero from "./assets/hero.png";
import Blast from "./assets/blast.png";
import Discord from "./assets/Discord.png";
import Twitter from "./assets/Twitter.png";
import Diamond from "./assets/Diamond.png";

function App() {
  return (
    <div className="bg-back-color bg-center flex flex-col h-screen w-screen fixed mb-2">
      <img
        className="ml-24 tl:ml-8 mt-8 tl:mb-8 w-[70px] h-[60px] tl:w-[50px] tl:h-[45px]"
        src={Logo}
      />
      <div className="flex justify-center items-center flex-col gap-4 tl:gap-8">
         <span className="tl:mt-[28px] font-shuriken font-normal text-[#FFCD62] text-7xl tl:text-3xl leading-[79.2px] tl:mt-[60px]">
          FORTUNE FELINES
        </span>
        <div className="flex flex-col justify-center items-center ">
          <img
            className="w-[660px] h-[380px] tl:h-[300px] tl:w-[800px] "
            src={Hero}
          />
          <img
            src={Diamond}
            className="animate-crystal-bounce fixed w-[300px] h-[300px] mt-[80px] tl:w-[200px] tl:h-[200px]"
          />
        </div>
        <div className="tl:mt-16 flex flex-row justify-center items-center gap-2">
          <span className="font-shuriken font-normal text-[#FFFFFF] text-5xl tl:text-2xl leading-[52.8px] tl:leading-[26.4px]">
            COMING SOON ON
          </span>
          <img
            className="w-[230px] h-[50px] tl:w-[112.48px] tl:h-[24px] mt-[-12px]"
            src={Blast}
          />
        </div> 
        <hr class="border-[#FFCD62] border-[1px] w-[245px]" />
        <div className="flex flex-row gap-8 mt-[">
          <a href="https://discord.gg/DFndu5VS" target="_blank">
            <img className="w-[30px] mt-2" src={Discord} />
          </a>
          <a href="https://twitter.com/_fortunefelines" target="_blank">
            <img className="w-[30px] mt-1" src={Twitter} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
